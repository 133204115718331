import './App.css';
import React from 'react';


class FilmItemRow extends React.Component {
  render() {
    return (
      <li>
        <a href={this.props.url}>{this.props.url}</a>
      </li>
    )
  }
}

class StarWars extends React.Component {
  getNewCharacter() {
    const randomNumber = Math.round(Math.random() * 82)
    const url = `https://swapi.dev/api/people/${randomNumber}`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        this.setState({
          name: data.name,
          height: data.height,
          homeworld: data.homeworld,
          films: data.films
        })
      })
    this.setState(
      {
        name: "luke",
        height: 3,
        homeworld: "tatione",
        films: ["item 1", "item2"],
        loadedCharacter: true
      }
    )
  }

  constructor() {
    super()
    this.state = {
      name: null,
      height: null,
      homeworld: null,
      films: [],
      loadedCharacter: false
    }
  }


  render() {

    const movies = this.state.films.map((url, i) => {
      return <FilmItemRow key={i} url={url}/>
    })

    return (
      <div>
        {
          this.state.loadedCharacter &&
          <div>
            <h1>
              {this.state.name}
            </h1>
            <p>{this.state.height} cm</p>
            <p><a href={this.state.homeworld}>Link to Homeworld</a></p>
            <ul>
              {movies}
            </ul>
          </div>

        }

        <button type="button" onClick={() => this.getNewCharacter()} className="btn">
          Randomize
        </button>
      </div>
    )
  }
}


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <StarWars />
      </header>
    </div>
  );
}

export default App;
